import { MapEmbed } from 'components/Map';
import { Github, LinkedinIn, Twitter, Telegram, Reddit } from 'styled-icons/fa-brands';
import { Email } from 'styled-icons/material';
import * as React from 'react';
export default {
  MapEmbed,
  Github,
  LinkedinIn,
  Twitter,
  Telegram,
  Reddit,
  Email,
  React
};
import fpProps from "../../../../content/posts/2018-07-27~brave-new-world/frameworkPopularity";
import saddleProps from "../../../../content/posts/2018-07-27~brave-new-world/saddle";
import triModalProps from "../../../../content/posts/2018-07-27~brave-new-world/triModal";
import { FoldingSpinner } from 'components/Spinners';
import DarkToggle from 'components/DarkToggle';
import { BorderBox } from 'components/styles';
import * as React from 'react';
export default {
  fpProps,
  saddleProps,
  triModalProps,
  FoldingSpinner,
  DarkToggle,
  BorderBox,
  React
};
import { ButtonLink } from 'components/styles';
import { Github, Twitter } from 'styled-icons/fa-brands';
import { DonateHeart } from 'styled-icons/boxicons-solid';
import * as React from 'react';
export default {
  ButtonLink,
  Github,
  Twitter,
  DonateHeart,
  React
};
import Image from 'gatsby-image';
import styled from 'styled-components';
import fig2 from "../../../../content/posts/2020-23-07~Grav/fig2.png";
import fig3 from "../../../../content/posts/2020-23-07~Grav/fig3.png";
import fig4 from "../../../../content/posts/2020-23-07~Grav/fig4.png";
import fig1o from "../../../../content/posts/2020-23-07~Grav/fig1.png";
import * as React from 'react';
export default {
  Image,
  styled,
  fig2,
  fig3,
  fig4,
  fig1o,
  React
};
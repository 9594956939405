import { MapEmbed } from 'components/Map';
import { Wordpress, LinkedinIn, Twitter, Instagram } from 'styled-icons/fa-brands';
import { Email } from 'styled-icons/material';
import Image from 'gatsby-image';
import styled from 'styled-components';
import logo from "../../../../content/posts/2020-23-09~lastfrontier/img_ali.png"; // with import

import * as React from 'react';
export default {
  MapEmbed,
  Wordpress,
  LinkedinIn,
  Twitter,
  Instagram,
  Email,
  Image,
  styled,
  logo,
  React
};